<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.owner.name.$errors.length }"
                        :label="$t('forms.name')"
                        label-for="name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="owner.name" id="name" type="text"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.owner.name.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="row"
                        :label="$t('forms.customer')"
                        label-for="customer"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="customer"
                            v-model="customerSelect"
                            :options="customers"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";

export default {
    components: {
        vSelect
    },
    props: {
        ownerObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            owner: {
                name: {required}
            },
        };
    },
    data() {
        return {
            customers: [],
            customerSelect: {},
            owner: this.ownerObject,
        };
    },
    created() {
        this.getUserList({params: {role: "ROLE_CUSTOMER"}}, this.customers);
        if (this.owner.customer) {
            this.customerSelect = {
                label: this.owner.customer.customer,
                id: this.owner.customer["@id"],
            }
        }
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.customerSelect) {
                    this.owner.customer = this.customerSelect.id;
                }
                if (this.action === "add") {
                    this.create(
                        this.$StockItemOwners,
                        this.owner,
                        this.$t("messages.stockItemOwnerCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$StockItemOwners,
                        this.owner.id,
                        this.owner,
                        this.$t("messages.stockItemOwnerUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/stock-item-owners`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
